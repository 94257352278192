






























































































































































































import {
  SfButton,
  SfHeading,
  SfImage,
  SfLink,
  SfLoader,
  SfTable,
  SfTabs
} from '~/components';

import {
  defineComponent,
  useAsync,
  useContext,
  useRouter,
  ref
} from '@nuxtjs/composition-api';

import { useUserOrder } from '~/modules/customer/composables/useUserOrder';
import orderGetters from '~/modules/checkout/getters/orderGetters';

import {
  useCountrySearch,
  useProduct,
  useWebsiteCode,
} from '~/composables';

import OrderSummaryRow from '~/modules/customer/pages/MyAccount/OrderHistory/SingleOrder/OrderSummaryRow.vue';
import OrderInformationAddressColumn
  from '~/modules/customer/pages/MyAccount/OrderHistory/SingleOrder/OrderInformationAddressColumn.vue';

export default defineComponent({
  name: 'SingleOrder',
  components: {
    SfHeading,
    SfButton,
    SfImage,
    SfLink,
    SfTable,
    SfTabs,
    SfLoader,
    OrderSummaryRow,
    OrderInformationAddressColumn,
  },
  props: { orderId: { type: String, required: true } },
  setup(props) {
    const context = useContext();
    const router = useRouter();
    const { search: searchOrder } = useUserOrder();
    const { search: searchCountries } = useCountrySearch();
    const { getProductPath } = useProduct();
    const { isAu } = useWebsiteCode();
    const giftWrap = ref();

    const orderData = useAsync(async () => {
      const orderResult = await searchOrder({ filter: { number: { eq: props.orderId } } });
      const order = orderResult.items[0] ?? null;
      giftWrap.value = orderResult.items[0]?.total?.gift_wrap ?? null;

      if (!order) {
        router.push(context.localeRoute({ name: 'customer-order-history' }));

        return null;
      }

      const uniqueCountryCodePromises = [...new Set([order.shipping_address.country_code, order.billing_address.country_code])]
        .map((countryCode) => searchCountries({ id: countryCode }));
      const countries = await Promise.all(uniqueCountryCodePromises);

      return {
        order,
        countries,
      };
    }, props.orderId);

    const ordersRoute = context.localeRoute({ name: 'customer-order-history' });

    return {
      ordersRoute,
      orderData,
      getProductPath,
      giftWrap,
      ...orderGetters,
      isAu,
    };
  },
});
